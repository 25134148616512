import { ReportsModule } from './views/pages/reports/reports.module';
// Angular
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Components
import {BaseComponent} from './views/theme/base/base.component';
import {ErrorPageComponent} from './views/theme/content/error-page/error-page.component';
// Auth
import {AuthGuard} from './core/auth';

const routes: Routes = [
	{path: 'auth', loadChildren: () => import('../app/views/pages/auth/auth.module').then(m => m.AuthModule)},
	{path: 'login', loadChildren: () => import('../app/views/pages/login/login.module').then(m => m.LoginModule)},
	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			// {
			// 	path: 'dashboard',
			// 	loadChildren: () => import('../app/views/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
			// },
			{
				path: 'transactions',
				loadChildren: () => import('../app/views/pages/transactions/transactions.module').then(m => m.TransactionsModule),
			},
			{
				path: 'masters',
				loadChildren: () => import('../app/views/pages/masters/masters.module').then(m => m.MastersModule),
			},
			{
				path: 'reports',
				loadChildren: () => import('../app/views/pages/reports/reports.module').then(m => m.ReportsModule),
			},
			{
				path: 'wizard',
				loadChildren: () => import('../app/views/pages/wizard/wizard.module').then(m => m.WizardModule),
			},
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v6',
					code: 403,
					title: '403... Access forbidden',
					desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator',
				},
			},
			{path: 'error/:type', component: ErrorPageComponent},
			{path: '', redirectTo: '/auth/login', pathMatch: 'full'},
			{path: '**', redirectTo: '/auth/login', pathMatch: 'full'},
		],
	},

	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: 'enabled', // Add options right here
			relativeLinkResolution: 'legacy',
			useHash: true 
		  }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
