import { Role } from './../../../../../core/auth/_models/role.model';
import { AuthService } from './../../../../../core/auth/_services/auth.service';
import { Router } from '@angular/router';
// Angular
import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
import { ToastrService } from 'ngx-toastr';
// import { currentUser, Logout, User } from '../../../../../core/auth/_services/auth.service';

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
	// Public properties
	// user$: Observable<User>;
	public logout:boolean=false;

	@Input() avatar = true;
	@Input() greeting = true;
	@Input() badge: boolean;
	@Input() icon: boolean;
	fullName: string;
	Role: string;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(
		private store: Store<AppState>,
		private router: Router,
		private auth: AuthService,
		private changeRef: ChangeDetectorRef,
		private toastr: ToastrService,
		) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.fullName = localStorage.getItem("fullName");
		this.Role = localStorage.getItem("role");
		// this.user$ = this.store.pipe(select(currentUser));
	}

	/**
	 * Log out
	 */
	// logout() {
		// this.store.dispatch(new Logout());
	// }

	logoutUser() {
		
		this.logout=true;
		this.auth.logoutUser().subscribe(
			succ=>{
				this.toastr.success('Success', 'Logout Successfully', {
					timeOut: 3000
				});
				this.logout=false;
                localStorage.removeItem('token');
                localStorage.removeItem('roleId');
                localStorage.removeItem('userId');
				localStorage.removeItem('lastSeen');
				localStorage.removeItem('fullName');
				localStorage.removeItem('permissions');
				localStorage.removeItem('moduleList');
				this.changeRef.detectChanges();
                this.router.navigate(['/login/log-out']);
				
            },
            error=>{
				this.logout=false;
			 
            }
        );
	}
}
