import { Subscription } from 'rxjs';
// Angular
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Layout
import { LayoutConfigService, SplashScreenService, TranslationService } from './core/_base/layout';
// language list
import { locale as enLang } from './core/_config/i18n/en';
import { locale as chLang } from './core/_config/i18n/ch';
import { locale as esLang } from './core/_config/i18n/es';
import { locale as jpLang } from './core/_config/i18n/jp';
import { locale as deLang } from './core/_config/i18n/de';
import { locale as frLang } from './core/_config/i18n/fr';
import { Spinkit } from 'ng-http-loader';
import { CookieService } from 'ngx-cookie-service';
@Component({
	// tslint:disable-next-line:component-selector
	selector: 'body[kt-root]',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
	// Public properties
	title = 'Metronic';
	loader: boolean;
	public spinkit = Spinkit;
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
	userName: any;
	userId: string;
	userNumber: any;
	lastSeen: any;

	/**
	 * Component constructor
	 *
	 * @param translationService: TranslationService
	 * @param router: Router
	 * @param layoutConfigService: LayoutCongifService
	 * @param splashScreenService: SplashScreenService
	 */
	constructor(private translationService: TranslationService,
		private router: Router,
		private layoutConfigService: LayoutConfigService,
		private splashScreenService: SplashScreenService,
		private cookieService: CookieService,
	) {
		console.log(this.cookieService.getAll());
		if (this.cookieService.check('token') && this.cookieService.check('moduleList') &&
			this.cookieService.check('permissions') && this.cookieService.check('userData')) {
				
				let token = JSON.parse(this.cookieService.get('token'));
				let moduleList = JSON.parse(this.cookieService.get('moduleList'));
				let permissions = JSON.parse(this.cookieService.get('permissions'));
				let userData = JSON.parse(this.cookieService.get('userData'));

			localStorage.setItem('token', token);
			localStorage.setItem('moduleList', JSON.stringify(moduleList));
			localStorage.setItem('permissions', JSON.stringify(permissions));
			localStorage.setItem('userData', JSON.stringify(userData));
			localStorage.setItem("lastSeen", userData.lastSeen);
			localStorage.setItem('roleId', userData.roleId);
			localStorage.setItem('role', userData.role);
			localStorage.setItem('userId', userData.id);
			let fullName = userData.firstName + ' ' + userData.lastName ;
			localStorage.setItem('fullName', fullName);
			this.userName = userData.name;
			this.userId = btoa(userData.id);
			this.userNumber = userData.mobile;
			this.lastSeen = userData.lastSeen;
			// this.cookieService.deleteAll();
			setTimeout(() => {
				this.router.navigate(['/transactions/approvals']);
			});
		}

		// register translations
		this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		// enable/disable loader
		this.loader = this.layoutConfigService.getConfig('loader.enabled');

		const routerSubscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				// hide splash screen
				this.splashScreenService.hide();

				// scroll to top on every route change
				window.scrollTo(0, 0);

				// to display back the body content
				setTimeout(() => {
					document.body.classList.add('kt-page--loaded');
				}, 500);
			}
		});
		this.unsubscribe.push(routerSubscription);
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.unsubscribe.forEach(sb => sb.unsubscribe());
	}
}
