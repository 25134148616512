import { LocalService } from './localStorage/services/local.service';
// Anglar
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Layout Directives
// Services
import {
	ContentAnimateDirective,
	FirstLetterPipe,
	GetObjectPipe,
	HeaderDirective,
	JoinPipe,
	MenuDirective,
	OffcanvasDirective,
	SafePipe,
	ScrollTopDirective,
	SparklineChartDirective,
	StickyDirective,
	TabClickEventDirective,
	TimeElapsedPipe,
	ToggleDirective,
} from './_base/layout';
import { SpaceTrimDirective } from './_base/layout/directives/space-trim.directive';
import { DeleteEntityDialogComponent } from '../views/partials/content/crud';
import { NumberDirective } from './_base/layout/directives/number-only.directive';
import { SymbolDirective } from './_base/layout/directives/symbol-only.directive';

@NgModule({
	imports: [CommonModule],
	declarations: [
		// directives
		ScrollTopDirective,
		HeaderDirective,
		OffcanvasDirective,
		ToggleDirective,
		MenuDirective,
		TabClickEventDirective,
		SparklineChartDirective,
		ContentAnimateDirective,
		StickyDirective,
		// pipes
		TimeElapsedPipe,
		JoinPipe,
		GetObjectPipe,
		SafePipe,
		FirstLetterPipe,
		SpaceTrimDirective,
		NumberDirective,
		SymbolDirective
	],
	exports: [
		// directives
		ScrollTopDirective,
		HeaderDirective,
		OffcanvasDirective,
		ToggleDirective,
		MenuDirective,
		TabClickEventDirective,
		SparklineChartDirective,
		ContentAnimateDirective,
		StickyDirective,
		// pipes
		TimeElapsedPipe,
		JoinPipe,
		GetObjectPipe,
		SafePipe,
		FirstLetterPipe,
		SpaceTrimDirective,
		NumberDirective,
		SymbolDirective
	],
	providers: []
	,entryComponents:[DeleteEntityDialogComponent]
})
export class CoreModule {
}
