import { Subscription } from 'rxjs';
import { SubheaderService } from './../../../../core/_base/layout/services/subheader.service';
// Angular
import { Component, AfterViewInit } from '@angular/core';

@Component({
	selector: 'kt-topbar',
	templateUrl: './topbar.component.html',
	styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {

	private subscriptions: Subscription[] = [];
	title: string = "";
	desc: string = "";

	constructor(public subheaderService: SubheaderService){

	}

ngAfterViewInit(): void {

	
	this.subscriptions.push(this.subheaderService.title$.subscribe(bt => {
		// breadcrumbs title sometimes can be undefined
		if (bt) {
			Promise.resolve(null).then(() => {
				this.title = bt.title;
				this.desc = bt.desc;
			});
		}
	}));
	
}

 }

 

