<kt-splash-screen *ngIf="loader"></kt-splash-screen>   
<router-outlet></router-outlet>
<ng-http-loader 
    [backdrop]="true"
    [backgroundColor]="'#5578eb'"
    [debounceDelay]="100"
    [extraDuration]="300"
    [minDuration]="300"
    [opacity]=".6"
    [spinner]="spinkit.skThreeBounce">
</ng-http-loader>
