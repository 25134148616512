import { Observable, BehaviorSubject, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalService {
  public moduleList:any;
  public permissionList:any;
  public subModulePermission:any;
  public roleId:any;
  constructor() { }

  // getModuleList(){
  //   let moduleData =  JSON.parse(localStorage.getItem("moduleList"));
  //   this.moduleList = moduleData.map(data => data.module_name);
  //   return this.moduleList;
  // }

  // getSubModulePermission(){
  //   let subModule = JSON.parse(localStorage.getItem("permissions"));
  //   this.subModulePermission = subModule.map(data => data.subModuleName);
  //   return this.subModulePermission;
  // }

  // getPermissions(){
  //   let permissionData = JSON.parse(localStorage.getItem("permissions"));
  //   this.permissionList = permissionData.map(data => data.action);
  //   return this.permissionList;
  // }
  // getRoleId(){
  //    this.roleId = localStorage.getItem("roleId");
  //   return this.roleId;
  //  }
}
